<template>
  <div>
    <Nav />

    <router-view/>

    
    <Footer />
    <Copyright /> 
    <ScrollTop />
    <QuickFunction />
    
  </div>
</template>

<script>
import Nav from '@/components/nav/Nav.vue'
import ScrollTop from '@/components/scrollTop/ScrollTop.vue'
import QuickFunction from '@/components/quickFunction/index.vue'
import Footer from '@/components/footer/index.vue'
import Copyright from '@/components/copyright/index.vue'

export default {
  components: {
    Nav,
    ScrollTop,
    QuickFunction,
    Footer,
    Copyright
  }
}
</script>

<style>
</style>
