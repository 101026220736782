<template>
  <footer id="footer" class="footer-area section-padding">
    <div class="container">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 tc wow fadeInUp" data-wow-delay="0.2s">
            <div class="mb-3"><img src="@/assets/logo.png" class="wt90"></div>
            <!-- <p>山东惠云软件科技有限公司</p> -->
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 wow fadeInUp" data-wow-delay="0.6s">
            <h3 class="footer-titel">我们的产品</h3>
            <ul>
              <li><a href="javascript:;" @click="$router.push({ path: '/schoolProduct' })">智慧校园平台</a></li>
              <li><a href="javascript:;" @click="$router.push({ path: '/governmentProduct' })">智慧政务平台</a></li>
              <li><a href="javascript:;" @click="$router.push({ path: '/medicalTreatmentProduct' })">医疗服务系统</a></li>
              <li><a href="javascript:;" @click="$router.push({ path: '/productHome' })">更多>></a></li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 wow fadeInUp" data-wow-delay="0.4s">
            <h3 class="footer-titel">行业解决方案</h3>
            <ul>
              <li><a href="javascript:;" @click="$router.push({ path: '/education' })">教育行业</a></li>
              <li><a href="javascript:;" @click="$router.push({ path: '/treatment' })">医疗服务</a></li>
              <li><a href="javascript:;" @click="$router.push({ path: '/government' })">政务服务</a></li>
              <li><a href="javascript:;" @click="$router.push({ path: '/industrySolutions' })">更多>></a></li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 wow fadeInUp" data-wow-delay="0.4s">
            <h3 class="footer-titel">技术方案</h3>
            <ul>
              <li><a href="javascript:;" @click="$router.push({ path: '/digitalTwins' })">数字孪生</a></li>
              <li><a href="javascript:;" @click="$router.push({ path: '/dataLargeScreen' })">大屏可视化</a></li>
              <li><a href="javascript:;" @click="$router.push({ path: '/iotPlatform' })">物联平台</a></li>
              <li><a href="javascript:;" @click="$router.push({ path: '/technicalProposal' })">更多>></a></li>
            </ul>
          </div>
          <!-- <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 wow fadeInUp" data-wow-delay="0.8s">
            <h3 class="footer-titel">Find us on</h3>
            <div class="social-icon">
              <a class="facebook" href="#"><i class="fa fa-area-chart"></i></a>
              <a class="twitter" href="#"><i class="fa fa-eyedropper"></i></a>
              <a class="instagram" href="#"><i class="fa fa-globe"></i></a>
              <a class="linkedin" href="#"><i class="fa fa-paper-plane-o"></i></a>
            </div>
          </div> -->
        </div>
      </div>  
    </div>     
  </footer> 
</template>
<script>
export default {
  mounted() { 
    new this.$wow.WOW().init()
  }
}
</script>
<style scoped>
.section-padding {
  padding: 60px 0;
}
/* Footer Area Start */
.footer-area {
  background: #34363a;
}
.footer-area p{
  color: #abacae;
  font-size: 14px;
}
.footer-area .footer-titel {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  padding-bottom: 10px;
  letter-spacing: 0.5px;
}

.footer-area ul li {
  margin-bottom: 10px;
}

.footer-area ul li a {
  color: #ccc;
  font-size: 14px;
  font-weight: 400;
}

.footer-area ul li a:hover {
  color: #137ED9;
}

.footer-area #subscribe-form {
  margin-top: 15px;
}

.footer-area #subscribe-form .form-group {
  position: relative;
}

.footer-area #subscribe-form .form-group .btn-common {
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px 15px;
  height: 39px;
  border-radius: 0;
  background: transparent;
  color: #137ED9;
  border-left: 1px solid #ddd;
}

.social-icon a {
  color: #666;
  background: #fff;
  width: 32px;
  height: 32px;
  line-height: 34px;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  font-size: 16px;
  margin: 15px 6px 12px 4px;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.social-icon a:hover {
  color: #fff !important;
}

.social-icon .facebook:hover {
  background: #3b5999;
}

.social-icon .twitter:hover {
  background: #4A9CEC;
}

.social-icon .instagram:hover {
  background: #D6274D;
}

.social-icon .linkedin:hover {
  background: #1260A2;
}

.social-icon .google:hover {
  background: #CE332A;
}
</style>