<template>
  <section class="ws-section-spacing fun-fact-bg">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="center-title">
            <h2 class="title">- 我们的数据 -</h2>
            <h4 class="sub-title">Our data</h4>
          </div>
        </div>
      </div>
  
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="fun-box wow lightSpeedIn">
            <h2 class="fun-fact-value counter">100</h2><span class="count">k</span>
            <p class="fun-fact-title">Happy Customer</p>
          </div>
        </div>
  
        <div class="col-lg-3 col-md-6">
          <div class="fun-box br-2 wow lightSpeedIn">
            <h2 class="fun-fact-value counter">80</h2><span class="count">k</span>
            <p class="fun-fact-title">活跃的用户</p>
          </div>
        </div>
  
  
        <div class="col-lg-3 col-md-6">
          <div class="fun-box br-3 wow lightSpeedIn">
            <h2 class="fun-fact-value counter">20</h2><span class="count">k</span>
            <p class="fun-fact-title">正面积极评价</p>
          </div>
        </div>
  
  
        <div class="col-lg-3 col-md-6">
          <div class="fun-box br-4 wow lightSpeedIn">
            <h2 class="fun-fact-value counter">15</h2><span class="count">k</span>
            <p class="fun-fact-title">涉及行业领域</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  
}
</script>
<style scoped>
.center-title {
  text-align: center;
  padding: 60px 0;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.ws-section-spacing {
  padding: 60px 0 180px 0;
}
/* ----------------------------------------------------------------
     [ 09 Fun-Fact-section ]
-----------------------------------------------------------------*/

.fun-fact-bg {
  background: url('~@/assets/fun-fact-bg2.png') right top no-repeat;
  height: 100%;
}

.fun-box {
  text-align: center;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgba(15, 90, 125, 0.15);
  z-index: 1;
  padding: 70px 18px;
  position: relative;
}

.fun-box:after {
  content: "";
  border: 1px dashed #51bef1;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  width: 90%;
  height: 90%;
}

.fun-fact-value {
  font-size: 45px;
  color: #51bef1;
  padding-bottom: 10px;
  display: inline-block;
}

.count {
  font-size: 45px;
  color: #51bef1;
  font-weight: 700;
}

.fun-fact-title {
  font-size: 20px;
  font-weight: 800;
}

.br-2, .br-4 {
  margin-top: 75px;
}

.fun-box.br-2:after {
  border: 1px dashed #e00457;
}

.fun-box.br-3:after {
  border: 1px dashed #35eeab;
}

.fun-box.br-4:after {
  border: 1px dashed #8a60ef;
}

.fun-box.br-2 .fun-fact-value {
  color: #e00457;
}

.fun-box.br-3 .fun-fact-value {
  color: #35eeab;
}

.fun-box.br-4 .fun-fact-value {
  color: #8B5FF0;
}

.fun-box.br-2 span {
  color: #e00457;
}

.fun-box.br-3 span {
  color: #35eeab;
}

.fun-box.br-4 span {
  color: #8B5FF0;
}


/* ----------------------------------------------------------------
     [ End Fun-Fact-section ]
-----------------------------------------------------------------*/
</style>