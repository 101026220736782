<template>
  <nav :class="nav ? 'colorlib-nav': 'colorlib-nav nav2'">
    <div class="top-menu">
      <div class="container">
        <div class="row">
          <div class="col-md-2">
            <!-- <div id="colorlib-logo"><router-link to="/"><i class="fa fa-paper-plane-o" aria-hidden="true"></i> 惠云软件</router-link></div> -->
            <div id="colorlib-logo">
              <router-link to="/">
                <img src="@/assets/logo2.png" class="wt120">
              </router-link>
            </div>
          </div>
          <div class="col-md-10 tr menu-1">
            <ul>
              <li 
                v-for="(item, index) in navList" 
                :key="index" 
                :class="item.route == currNav ? 'active' : ''"
                @mouseenter="handleMouseenter(index)"
                @mouseleave="handleMouseleave()"
              >
                <span @click="handleLink(index, item.route)">{{ item.name }}</span>
                <div v-show="currSubNav == index" class="subNav animated flipInX">
                  <p 
                    v-for="(itemb, indexb) in item.children" 
                    :key="indexb"
                    @click="handleLink(index, itemb.route)">
                    {{ itemb.name }}
                  </p>
                </div>
                <!-- <div v-if="index == 2" v-show="currSubNav == 2" class="subNav animated flipInX">
                  <p>教育行业</p>
                  <p>医疗行业</p>
                  <p>政务服务</p>
                </div>
                <div v-if="index == 3" v-show="currSubNav == 3" class="subNav animated flipInX">
                  <p>数字孪生</p>
                  <p>大屏可视化</p>
                  <p>物联平台</p>
                </div> -->
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      navList: [
        { name: '首页', route: '/home', children: [] },
        { 
          name: '我们的产品', 
          route: '/product', 
          children: [
            { name: '智慧校园', route: '/schoolProduct' },
            { name: '智慧政务', route: '/governmentProduct' },
            { name: '智慧医疗', route: '/medicalTreatmentProduct' },
            { name: '隐患管理', route: '/hiddenDangerProduct' },
            { name: '实验室管理', route: '/laboratoryProduct' }
          ] 
        },
        { 
          name: '行业解决方案', 
          route: '/industrySolutions',
          children: [
            { name: '教育行业', route: '/education' },
            { name: '医疗行业', route: '/treatment' },
            { name: '政务服务', route: '/government' }
          ]
        },
        { 
          name: '技术方案', 
          route: '/technicalProposal',
          children: [
            { name: '大屏可视化', route: '/dataLargeScreen' },
            { name: '工作流引擎', route: '/workflowEngine' },
            { name: '报表设计器', route: '/reportDesigner' },
            { name: '表单设计器', route: '/formDesigner' },
            { name: '3D数字孪生', route: '/digitalTwins' },
            { name: '物联平台', route: '/iotPlatform' },
          ]
        },
        { name: '关于我们 ', route: '/contactUs', children: [] }
      ],
      currSubNav: -1,
      currNav: '/home',
      nav: true
    }
  },
  watch: {
    '$route': {
      handler(to){
        this.findoneLevelRouter(to.path)
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    let currNav = sessionStorage.getItem('currNav');
    if (currNav) {
      this.currNav = currNav;
    }
  },
  methods: {
    handleScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop == 0) {
        this.nav = true;
        return;
      } else {
        this.nav = false
      }
    },
    handleLink(index, path) {
      if (path === '/product') {
        const routeLink = this.$router.resolve({ path: path });
        window.open(routeLink.href, '_blank');
      } else {
        // sessionStorage.setItem('currNav', index);
        // this.currNav = index;
        this.$router.push({ path: path });
      }
    },
    handleMouseenter(index) {
      this.currSubNav = index;
    },
    handleMouseleave() {
      this.currSubNav = -1;
    },
    findoneLevelRouter(path) {
      let routeList = [];
      this.navList.forEach(item => {
        let arr = [];
        arr.push(item.route)
        item.children.forEach(itemb => { arr.push(itemb.route) })
        routeList.push(arr)
      })
      routeList.forEach(item => {
        if (item.includes(path)) {
          let currNav = item[0];
          sessionStorage.setItem('currNav', currNav);
          this.currNav = currNav;
        }
      })
    }
  }
}
</script>

<style scoped>

.nav2 {
  background-color: #597fdd !important;
  box-shadow: 0px 0px 13px rgb(1 12 79 / 36%);
}

.colorlib-nav {
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  margin: 0 auto;
}
    
.colorlib-nav .top-menu {
  padding: 30px 30px; 
}

.colorlib-nav #colorlib-logo {
  font-size: 26px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: 700; 
}
.colorlib-nav #colorlib-logo a {
  position: relative;
  color: #fff; 
}

.colorlib-nav ul {
  padding: 0;
  margin: 0; 
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
.colorlib-nav ul li {
  /* padding: 8px 0; */
  margin: 0;
  list-style: none;
  /* display: inline; */
  font-weight: 500;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 2px; 
  display: flex;
  align-content: center;
  margin-left: 20px;
  position: relative;
}
.colorlib-nav ul li span {
  position: relative;
  padding: 20px 12px;
  color: rgba(255, 255, 255, 0.9);
  font-weight: bold;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s; 
  cursor:pointer;
}
.colorlib-nav ul li span:hover {
  color: #fff; 
}
.colorlib-nav ul li.active > span {
  /* color: rgba(255, 255, 255, 0.5); */
  color: #fff;
  position: relative; 
  z-index: 1;
}
/* .colorlib-nav ul li.active > span:after {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); 
} */

.colorlib-nav ul li.active > span:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  right: 0;
  bottom: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #80bf63;
  /* border-radius: 5px; */
}

.colorlib-nav ul li .subNav {
  position: absolute;
  top: 58px;
  width: 132px;
  text-align: center;
  background: #fff;
  box-shadow: 2px 4px 6px rgba(106, 106, 106, 0.81);
}
.colorlib-nav ul li .subNav p {
  padding: 6px 0;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}
.colorlib-nav ul li .subNav p:hover {
  background-color: #80bf63;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .colorlib-nav { margin: 0; } 
  .colorlib-nav .top-menu { padding: 28px 1em; } 
  .colorlib-nav .menu-1 { display: none; }
}

</style>
