<template>
  <section id="copyright">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <p>
            Copyright © 2023 山东惠云软件版权所有 -  
            <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备2022041268号-1</a>
          </p>
        </div>
      </div>
    </div>
  </section>   
</template>
<script>
export default {
  
}
</script>
<style scoped>
#copyright {
  background: #34363a;
  border-top: 2px dotted #3b3d42;
  padding: 10px;
}

#copyright p {
  line-height: 42px;
  color: #fff;
  text-align: center;
  margin: 0;
  font-size: 14px;
}

#copyright p a {
  color: #fff;
}

#copyright p a:hover {
  color: #137ED9;
}

</style>