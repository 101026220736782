<template>
  <section class="ws-section-spacing feature-bg bgwhitesmoke" id="Features">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="center-title">
            <h2 class="title">- 我们的产品 -</h2>
            <h4 class="sub-title">OUR PRODUCTS</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12" @click="handleLink('/schoolProduct')">
          <div class="features wow bounceInLeft">
            <div class="feature-iocn">
              <span class="iocn-bg"><i class="fa fa-paper-plane-o" aria-hidden="true"></i></span>
            </div>
            <div class="feature-contain">
              <h4 class="feature-title">智慧校园</h4>
              <p>面向客户：小、中、高校园皆能满足</p>
              <p>产品亮点：教师成长规划、绩效考核、宿舍管理</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12" @click="handleLink('/laboratoryProduct')">
          <div class="features active wow bounceInRight">
            <div class="feature-iocn">
              <span class="iocn-bg bg-02"><i class="fa fa-life-ring" aria-hidden="true"></i></span>
            </div>
            <div class="feature-contain">
              <h4 class="feature-title">实验室管理</h4>
              <p>面向客户：检测单位、高校、化工厂、科研团队</p>
              <p>产品亮点：“码”上可查、数据可视化、电子签名/签章</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12" @click="handleLink('/medicalTreatmentProduct')">
          <div class="features wow bounceInLeft">
            <div class="feature-iocn">
              <span class="iocn-bg bg-03"><i class="fa fa-window-restore" aria-hidden="true"></i></span>
            </div>
            <div class="feature-contain">
              <h4 class="feature-title">医疗服务平台</h4>
              <p>面向客户：医院</p>
              <p>产品亮点：随访管理、医随APP、病情变化跟踪</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12" @click="handleLink('/hiddenDangerProduct')">
          <div class="features wow bounceInRight">
            <div class="feature-iocn">
              <span class="iocn-bg bg-04"><i class="fa fa-google-plus" aria-hidden="true"></i></span>
            </div>
            <div class="feature-contain">
              <h4 class="feature-title">隐患管理</h4>
              <p>面向客户：企业园区、工厂、医院、学校</p>
              <p>产品亮点：风险管控、隐患排查治理、电子巡检、隐患跟踪</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mtb20">
        <div class="col-lg-12 col-md-12 tc">
          <a href="javascript:;" class="ft18" @click="$router.push({ path: '/productHome' })">- 了解更多 >></a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    handleLink(path) {
      const routeLink = this.$router.resolve({ path: path });
      window.open(routeLink.href, '_blank');
    },
  }
}
</script>

<style scoped>

.center-title {
  text-align: center;
  padding: 60px 0;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 20px;
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.center-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 18px;
  /* line-height: 28px; */
  max-width: 750px;
  margin: 0 auto;
  font-weight: 500;
}
/* ----------------------------------------------------------------
[ 04 Start Features ]
-----------------------------------------------------------------*/
.ws-section-spacing {
  padding: 0 0 30px 0;
}

.feature-bg {
  /* background-image: url(../images/feature-bg.png); */
  width: 100%;
  background-repeat: no-repeat;
}

.features {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 3px 19px 0px rgba(40, 44, 71, 0.18);
  padding: 30px;
  margin-bottom: 30px;
  cursor: pointer;
}

.feature-iocn {
  float: left;
  width: 20%;
}

.feature-iocn span.iocn-bg i:before {
  font-size: 40px;
}

.iocn-bg {
  width: 83px;
  height: 83px;
  border-radius: 60px;
  background-color: #cdffb6;
  display: block;
  color: #598e3e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-02 {
  background-color: #FFF;
  color: #5569e5;
}

.bg-03 {
  background-color: #fdffb6;
  color: #a6a668;
}

.bg-04 {
  background-color: #b6f4ff;
  color: #53939d;
}

.feature-contain {
  width: 75%;
  margin-left: auto;
}

.feature-title {
  margin-bottom: 20px;
}

.features:hover {
  background-color: #5569e5;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

.features.active {
  background-color: #5569e5;
}

.features.active .feature-contain h4 {
  color: #fff;
}

.features.active .feature-contain p {
  color: #fff;
}

.features:hover .feature-iocn .iocn-bg {
  background-color: #FFF;
  color: #5569e5;
}

.features:hover .feature-contain h4 {
  color: #fff;
}

.features:hover .feature-contain p {
  color: #fff;
}

/* ----------------------------------------------------------------
     [ End Features-Section ]
-----------------------------------------------------------------*/
</style>