<template>
  <section class="video-hero">
    <div class="overlay"></div>
    <div class="display-t text-center">
      <div class="display-tc">
        <div class="container">
          <div class="col-md-12 col-md-offset-0">
            <div class="animate-box">
              <div class="ft30 bold white mb20"> - 社会的进步取决于人类为传统和未来而行动 - </div>
              <p>The progress of society depends on the actions of human beings for the tradition and the future</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {
    
  }
}
</script>

<style scoped>
.video-hero {
  height: 430px;
  background-image: url('/img/cover_img_1.jpg');
  /* background-image: url('/img/banner.png'); */
  background-position: center center;
  background-attachment: fixed;
  background-size: 100% 100%;
  position: relative;
  background-repeat: no-repeat;
  z-index: 0; 
}
/* 
.video-hero:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.9)), color-stop(58.44%, rgba(0, 0, 0, 0)));
    background: -o-linear-gradient(left, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 58.44%);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 58.44%);
    z-index: 1;
} */


@media screen and (max-width: 768px) {
  .video-hero {
    height: inherit;
    padding: 3em 0; 
  } 
  .video-hero .display-t {
    height: 400px; 
  }
  .video-hero .display-tc h2 {
    font-size: 34px; 
  }
}

.video-hero .overlay {
  position: absolute; top: 0; bottom: 0; left: 0; right: 0; opacity: 0.6;
  background: #499bea;
  background: #499bea;
  background: -moz-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, #499bea), color-stop(100%, #798eea));
  background: -webkit-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: -o-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: -ms-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: -webkit-linear-gradient(315deg, #499bea 0%, #798eea 100%);
  background: -o-linear-gradient(315deg, #499bea 0%, #798eea 100%);
  background: linear-gradient(135deg, #499bea 0%, #798eea 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#499bea', endColorstr='#798eea', GradientType=1 );
  z-index: 1; 
}

.video-hero .display-t {
  display: table;
  height: 450px;
  width: 100%;
  position: relative;
  z-index: 2; 
  padding-top: 60px;
}
.video-hero .display-tc {
  display: table-cell;
  vertical-align: middle; 
}

.video-hero .display-tc h2 {
  color: #fff;
  font-size: 40px;
  margin-bottom: 30px;
  font-weight: 500; 
}
.video-hero .display-tc p {
  color: #fff;
  font-weight: 200;
  font-size: 20px; 
  margin-bottom: 30px;
}

.video-hero .display-tc .btn-custom {
  font-size: 16px; 
}

</style>
