<template>
  <section class="ws-section-spacing" id="Pricing">
    <div class="container">

      <div class="row">
        <div class="col-lg-12">
          <div class="center-title">
            <h2 class="title">- 技术方案 -</h2>
            <h4 class="sub-title">TECHNICAL PROPOSAL</h4>
          </div>
        </div>
      </div>
  
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="price-box wow bounceInLeft">
            <div class="pricing-header mb40">
              <h3 class="price-title">
                <i class="fa fa-cogs" aria-hidden="true"></i>
                3D数字孪生
              </h3>
            </div>
  
            <!-- <div class="pricing-value">
              <h2 class="price-value">￥300<small>/每月</small></h2>
            </div> -->
  
            <div class="pricing-item">
              <ul>
                <li>完整呈现周边环境</li>
                <li>场景化运营管理</li>
                <li>3D场景搭建</li>
                <li>多层级管理</li>
              </ul>
            </div>
  
            <div class="pricing-footer">
              <a href="javascript:;" @click="$router.push({ path: '/digitalTwins' })" class="pricing-btn">了解详情</a>
            </div>
          </div>
        </div>
  
        <div class="col-lg-3 col-md-6 col-sm-12 ">
          <div class="price-box active wow bounceOut">
            <!-- <div class="recommend">公司推荐</div> -->
            <div class="pricing-header mb40">
              <h3 class="price-title">
                <i class="fa fa-window-maximize" aria-hidden="true"></i>
                大屏可视化
              </h3>
            </div>
  
            <!-- <div class="pricing-value">
              <h2 class="price-value">￥400<small>/每月</small></h2>
            </div> -->
  
            <div class="pricing-item">
              <ul>
                <li>多维数据同步展示</li>
                <li>图表、图形、地图</li>
                <li>数据实时分析</li>
                <li>数据实时反馈</li>
              </ul>
            </div>
  
            <div class="pricing-footer">
              <a href="javascript:;" @click="$router.push({ path: '/dataLargeScreen' })" class="pricing-btn">了解详情</a>
            </div>
  
          </div>
        </div>
  
        <div class="col-lg-3 col-md-6 col-sm-12 ">
          <div class="price-box wow bounceInRight">
            <div class="pricing-header mb40">
              <h3 class="price-title">
                <i class="fa fa-cubes" aria-hidden="true"></i>
                物联平台
              </h3>
            </div>
  
            <!-- <div class="pricing-value">
              <h2 class="price-value">￥600<small>/每月</small></h2>
            </div> -->
  
            <div class="pricing-item">
              <ul>
                <li>监控连接互动</li>
                <li>信息传感器</li>
                <li>识别技术</li>
                <li>定位系统</li>
              </ul>
  
            </div>
  
            <div class="pricing-footer">
              <a href="javascript:;" @click="$router.push({ path: '/iotPlatform' })" class="pricing-btn">了解详情</a>
            </div>
  
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-12 ">
          <div class="price-box active wow bounceOut">
            <!-- <div class="recommend">公司推荐</div> -->
            <div class="pricing-header mb40">
              <h3 class="price-title">
                <i class="fa fa-random" aria-hidden="true"></i>
                工作流引擎
              </h3>
            </div>
  
            <!-- <div class="pricing-value">
              <h2 class="price-value">￥400<small>/每月</small></h2>
            </div> -->
  
            <div class="pricing-item">
              <ul>
                <li>流程的灵活性体现</li>
                <li>独特中间层理念</li>
                <li>可视化低代码</li>
                <li>高配置易扩展</li>
              </ul>
            </div>
  
            <div class="pricing-footer">
              <a href="javascript:;" @click="$router.push({ path: '/dataLargeScreen' })" class="pricing-btn">了解详情</a>
            </div>
  
          </div>
        </div>
      </div>

      <div class="row mtb20">
        <div class="col-lg-12 col-md-12 tc">
          <a href="javascript:;" class="ft18" @click="$router.push({ path: '/technicalProposal' })">- 了解更多 >></a>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  
}
</script>

<style scoped>
.center-title {
  text-align: center;
  padding: 60px 0;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 20px;
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.center-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 18px;
  /* line-height: 28px; */
  max-width: 750px;
  margin: 0 auto;
  font-weight: 500;
}

.ws-section-spacing {
  padding: 0 0 60px 0;
  /* background: url('~@/assets/testimonial-bg.png') right bottom no-repeat; */
  /* background-repeat: no-repeat; */
}

/* ----------------------------------------------------------------
[ 08 Price-section ]
-----------------------------------------------------------------*/

.price-box {
  /* border: 1px solid #596dee; */
  box-shadow: 0px 0px 35px 0px rgba(40, 44, 71, 0.15);
  border-radius: 8px;
  background-color: #fff;
  padding: 45px 0px 45px 0;
  text-align: center;
  overflow: hidden;
  margin-bottom: 30px;
  margin-top: 20px;
}

.recommend {
  width: 65%;
  padding: 9PX 0px 9px 33px;
  background-color: #596dee;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  transform: rotate(27deg);
  position: relative;
  left: 45%;
  top: -38px;
}

.pricing-value {
  padding: 35px 0 35px 0;
}

.price-value {
  font-size: 41px;
}

.price-title {
  font-size: 22px;
  font-weight: 700;
}

.pricing-item {
  margin-bottom: 20px;
}

.pricing-item ul li {
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 28px;
  margin-bottom: 0;
}

.pricing-btn {
  border-radius: 8px;
  background-color: #5a68ef;
  padding: 10px 45px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
}

.price-box:hover {
  box-shadow: 0px 0px 35px 0px rgba(40, 44, 71, 0.15);
  border: none;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

.price-box:hover .pricing-btn {
  background-color: #ff8a41;
  color: #fff;
  transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

.price-box.active {
  border: none;
  box-shadow: 0px 0px 35px 0px rgba(40, 44, 71, 0.15);
  margin-top: 0px;
}

.price-box.active .pricing-btn {
  background-color: #ff8a41;
}

/* ----------------------------------------------------------------
     [ End Price-section ]
-----------------------------------------------------------------*/

</style>