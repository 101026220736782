<template>
  <div class="colorlib-services colorlib-bg-white">
    <div class="container">
      <div class="row">
        <div class="col-md-4 text-center wow bounceInLeft">
          <div class="services" @click="$router.push({ path: '/education' })">
            <span class="icon"><i class="gray4 fa fa fa-leanpub"></i></span>
            <div class="desc">
              <h3>教育行业</h3>
              <p>助力高校人才培养、科研创新。均衡区域教育资源，提升最佳泛在学习体验，提升教学质量和科学管理，促进教育公平</p>
            </div>
            <div class="w30 mt40 gray9 ft14 ptb5 radius8 mauto">
              <a href="javascript:;" @click="$router.push({ path: '/education' })">去了解 >></a>
            </div>
          </div>
        </div>
        <div class="col-md-4 text-center wow bounceOut">
          <div class="services" @click="$router.push({ path: '/government' })">
            <span class="icon"><i class="gray4 fa fa-handshake-o"></i></span>
            <div class="desc">
              <h3>政务服务</h3>
              <p>实现统一建设，推动多端延伸，打破部门壁垒，加强资源整合，智能交互服务，提升用户体验，优化流程，便捷办事服务，构建评价体系</p>
            </div>
            <div class="w30 mt40 gray9 ft14 ptb5 radius8 mauto">
              <a href="javascript:;" @click="$router.push({ path: '/government' })">去了解 >></a>
            </div>
          </div>
        </div>
        <div class="col-md-4 text-center wow bounceInRight">
          <div class="services" @click="$router.push({ path: '/treatment' })">
            <span class="icon"><i class="gray4 fa fa fa-heartbeat"></i></span>
            <div class="desc">
              <h3>医疗行业</h3>
              <p>医疗数据全生命周期掌控，对各个环节的监控，提前对可能发生的数据泄露风险进行预警，保障了数据在安全可控的范围内使用</p>
            </div>
            <div class="w30 mt40 gray9 ft14 ptb5 radius8 mauto">
              <a href="javascript:;" @click="$router.push({ path: '/treatment' })">去了解 >></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>
.services:hover {
  box-shadow: 0px 0px 35px 0px rgba(40, 44, 71, 0.15);
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}
.services:hover .icon {
  transform: scale(1.3);
  transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
}

.colorlib-services {
  padding: 60px 0 60px 0;
  clear: both; 
}
.colorlib-bg-white {
  background: #fff;
  border: 1px solid #e6e6e6;
  border-left: none;
  border-right: none; 
}
.services {
  position: relative;
  z-index: 0;
  padding: 20px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0px 0px 35px 0px rgba(40, 44, 71, 0.15);
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .services {
    margin-bottom: 3em; 
  } 
}
.services .icon {
  position: relative;
  margin-bottom: 30px;
  display: inline-block; 
}
.services .icon i {
  font-size: 50px;
  color: #798eea;
  z-index: 1; 
}
.services .desc h3 {
  font-size: 26px;
  font-weight: 300; 
  color: #303133;
  font-family: "Nunito", Arial, sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  line-height: 1.3;
}
.services .desc p {
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 2;
  color: gray;
}
</style>