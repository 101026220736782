import router from './index'

router.beforeEach((to, from, next) => {
  console.log(to)
  // 路由跳转后，让页面回到顶部
  document.body.scrollTop = 0; 
  document.documentElement.scrollTop = 0; 

  next();
})

router.afterEach(() => {

})