<template>
  <div class="quickFunction">
    <div class="quickFunctionItem" @click="handleLink">
      <img src="@/assets/advisory.png" alt="scroll-top">
      <p>联系我们</p>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    handleLink() {
      let meta = this.$route.meta;
      let title = '';
      if (meta?.type == 'technology' || meta?.type == 'product') { title = meta?.title }
      this.$router.push({ 
        path: '/contactUs', 
        query: { contact: true, title }
      });
    },
  }
}
</script>
<style scoped>
.quickFunction {
  width: 70px;
  height: 80px;
  background: #fff;
  box-shadow: 0 2px 10px 0 rgb(171 171 171 / 24%);
  border-radius: 6px;
  position: fixed;
  bottom: 150px;
  right: 26px; 
  z-index: 9999;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}
.quickFunction .quickFunctionItem {
  cursor: pointer;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}
.quickFunction .quickFunctionItem p {
  font-size: 12px;
  margin-top: 5px;
}
</style>