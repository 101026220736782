import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './router/routerWatch'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/button.css';
import './assets/common.css';
import './utils/skip'

import wow from 'wowjs'
Vue.prototype.$wow = wow

import 'font-awesome/css/font-awesome.css'
import animated from 'animate.css' 
Vue.use(animated)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
