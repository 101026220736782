<template>
  <div>
    <Section />
    <Modular1 />
    <Modular3 />
    <Modular4 />
    <!-- <Modular5 /> -->
  </div>
</template>

<script>
import Section from './components/section/Section2.vue'
import Modular1 from './components/modular1/index.vue'
import Modular3 from './components/modular3/index.vue'
import Modular4 from './components/modular4/index.vue'
import Modular5 from './components/modular5/index.vue'

export default {
  components: {
    Section,
    Modular1,
    Modular3,
    Modular4,
    Modular5
  }
}
</script>

<style>
</style>
