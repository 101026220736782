(function () {

  // 第一种方式通过判断是否是移动设备
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
    // var a = document.referrer, 
    // b = { 
    //   "baidu.com": "seo_baidu", 
    //   "sogou.com": "seo_sogou", 
    //   "sm.cn": "seo_sm", 
    //   "so.com": "seo_360", 
    //   "bing.com": "seo_bing", 
    //   "google.com": "seo_google" 
    // }, c; 
    // for (c in b) { 
    //   if (-1 != a.indexOf(c)) { 
    //     a = b[c]; 
    //     if (window.sessionStorage) { 
    //       sessionStorage.setItem("channel", a) 
    //     } else { 
    //       // eslint-disable-next-line no-redeclare
    //       var d = d || 0, b = ""; 
    //       0 != d && (b = new Date, b.setTime(b.getTime() + 1000 * d), b = "; expires=" + b.toGMTString()); 
    //       document.cookie = "channel=" + escape(a) + b + "; path=/" 
    //     } 
    //     break 
    //   } 
    // }
    // window.location.href = "/m/" + location.search;
    window.location.href = "http://huiyun.cc/HyWebsiteMobile";
  }

})();

// 获取屏幕信息
const getWindowInfo = () => {
  const windowInfo = { width: window.innerWidth, hight: window.innerHeight	}
  console.log(windowInfo)
  if (windowInfo.width < 1000) {
    // window.removeEventListener('resize', cancalDebounce);
    window.location.href = "http://huiyun.cc/HyWebsiteMobile";
  }
};

// 需要防抖，无论我们怎么拖动窗口大小，只执行一次获取浏览器宽高的方法
const debounce = (fn, delay) => {
	let timer;
	return function() {
		if (timer) {
			clearTimeout(timer);
		}
		timer = setTimeout(() => {
			fn();
		}, delay);
	}
};
const cancalDebounce = debounce(getWindowInfo, 500);

// 第二种方式在PC浏览器上通过判断屏幕宽度
if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
  if (window.innerWidth < 1000) { window.location.href = "http://huiyun.cc/HyWebsiteMobile"	}
  window.addEventListener('resize', cancalDebounce);
}
