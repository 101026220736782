import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/index.vue'
import Index from '@/views/index/index.vue'

// hack router push callback
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    redirect:'/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: Home,
      },
      {
        path: '/industrySolutions',
        name: 'industrySolutions',
        component: () => import(/* webpackChunkName: "home" */ '@/views/industrySolutions/index.vue')
      },
      {
        path: '/education',
        name: 'education',
        component: () => import(/* webpackChunkName: "home" */ '@/views/industrySolutions/education/index.vue'),
      },
      {
        path: '/treatment',
        name: 'treatment',
        component: () => import(/* webpackChunkName: "home" */ '@/views/industrySolutions/treatment/index.vue'),
      },
      {
        path: '/government',
        name: 'government',
        component: () => import(/* webpackChunkName: "home" */ '@/views/industrySolutions/government/index.vue'),
      },
      {
        path: '/technicalProposal',
        name: 'technicalProposal',
        component: () => import(/* webpackChunkName: "home" */ '@/views/technicalProposal/index.vue'),
      },
      {
        path: '/digitalTwins',
        name: 'digitalTwins',
        component: () => import(/* webpackChunkName: "home" */ '@/views/technicalProposal/digitalTwins/index.vue'),
        meta: {
          type: 'technology',
          title: '3D数字孪生' 
        }
      },
      {
        path: '/dataLargeScreen',
        name: 'dataLargeScreen',
        component: () => import(/* webpackChunkName: "home" */ '@/views/technicalProposal/dataLargeScreen/index.vue'),
        meta: {
          type: 'technology',
          title: '大屏可视化' 
        }
      },
      {
        path: '/iotPlatform',
        name: 'iotPlatform',
        component: () => import(/* webpackChunkName: "home" */ '@/views/technicalProposal/iotPlatform/index.vue'),
        meta: {
          type: 'technology',
          title: '物联平台' 
        }
      },
      {
        path: '/reportDesigner',
        name: 'reportDesigner',
        component: () => import(/* webpackChunkName: "home" */ '@/views/technicalProposal/reportDesigner/index.vue'),
        meta: {
          type: 'technology',
          title: '报表设计器' 
        }
      },
      {
        path: '/formDesigner',
        name: 'formDesigner',
        component: () => import(/* webpackChunkName: "home" */ '@/views/technicalProposal/formDesigner/index.vue'),
        meta: {
          type: 'technology',
          title: '表单设计器' 
        }
      },
      {
        path: '/workflowEngine',
        name: 'workflowEngine',
        component: () => import(/* webpackChunkName: "home" */ '@/views/technicalProposal/workflowEngine/index.vue'),
        meta: {
          type: 'technology',
          title: '工作流引擎' 
        }
      },
      {
        path: '/contactUs',
        name: 'contactUs',
        component: () => import(/* webpackChunkName: "home" */ '@/views/contactUs/index.vue'),
      }
    ]
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import(/* webpackChunkName: "product" */ '@/views/product/index.vue'),
    redirect:'/productHome',
    children: [
      {
        path: '/productHome',
        name: 'productHome',
        component: () => import(/* webpackChunkName: "product" */ '@/views/product/productHome/index.vue'),
      },
      {
        path: '/schoolProduct',
        name: 'schoolProduct',
        component: () => import(/* webpackChunkName: "product" */ '@/views/product/schoolProduct/index.vue'),
        meta: {
          type: 'product',
          title: '智慧校园'
        }
      },
      {
        path: '/governmentProduct',
        name: 'governmentProduct',
        component: () => import(/* webpackChunkName: "product" */ '@/views/product/governmentProduct/index.vue'),
        meta: {
          type: 'product',
          title: '智慧政务'
        }
      },
      {
        path: '/medicalTreatmentProduct',
        name: 'medicalTreatmentProduct',
        component: () => import(/* webpackChunkName: "product" */ '@/views/product/medicalTreatmentProduct/index.vue'),
        meta: {
          type: 'product',
          title: '智慧医疗'
        }
      },
      {
        path: '/hiddenDangerProduct',
        name: 'hiddenDangerProduct',
        component: () => import(/* webpackChunkName: "product" */ '@/views/product/hiddenDangerProduct/index.vue'),
        meta: {
          type: 'product',
          title: '隐患管理平台'
        }
      },
      {
        path: '/laboratoryProduct',
        name: 'laboratoryProduct',
        component: () => import(/* webpackChunkName: "product" */ '@/views/product/laboratoryProduct/index.vue'),
        meta: {
          type: 'product',
          title: '实验室管理平台' 
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
