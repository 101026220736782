<template>
  <a href="#" id="btn-to-top" class="show"> <img src="@/assets/scroll-top.gif" alt="scroll-top"></a>
</template>
<script>
export default {
  
}
</script>
<style scoped>
#btn-to-top {
  display: inline-block;
  width: 104px;
  height: 80px;
  text-align: center;
  font-size: 12px;
  position: fixed;
  bottom: 40px;
  right: 10px; 
  visibility: hidden;
  z-index: 999;
  transform: scale(1.1,1.1);
  transition: all 0.5s ease;
}
#btn-to-top img {
  width: 100%;
  height: 100%;
}

#btn-to-top.show {
  opacity: 1;
  visibility: visible;
}
</style>